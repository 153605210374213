import { SystemProps, x } from '@xstyled/styled-components';
import { PropsWithChildren } from 'react';

import { Icon } from '../../common/components/Icon';
import { Text } from '../../common/components/Text';

export const LandingArrowButton: React.FC<
  PropsWithChildren<{ variant?: 'light' | 'yellow' | 'dark' } & SystemProps>
> = ({ variant = 'light', children, ...props }) => {
  const bg = {
    light: 'white',
    yellow: 'yellow.300',
    dark: 'gray.500',
  }[variant];

  const color = {
    light: 'gray.500',
    yellow: 'gray.500',
    dark: 'white',
  }[variant];

  return (
    <x.div
      display="inline-flex"
      spaceX={3}
      py={2}
      px={4}
      color={color}
      bg={bg}
      borderRadius="md"
      alignItems="center"
      cursor="pointer"
      {...props}
    >
      <Text variant={{ _: 'sm-semibold', lg: 'md-semibold' }}>{children}</Text>
      <Icon name="arrow-right" size="5" />
    </x.div>
  );
};
