import { routes } from '@frond/shared';
import { ThemeProvider, x } from '@xstyled/styled-components';
import { NextSeo } from 'next-seo';
import React, { useState } from 'react';

import { ViewerQuery } from '../../../../generated/graphql-request-api-sdk';
import {
  BASE_URL,
  DEFAULT_OG_IMAGE_URL,
  IS_DEMO,
} from '../../../config/constants';
import { SignInRedirectGuard } from '../../auth/components/guards/SignInRedirectGuard';
import { SignInModal } from '../../auth/components/SignIn/SignInModal';
import { Button } from '../../common/components/Button';
import { FrondLogo } from '../../common/components/FrondLogo';
import { Heading } from '../../common/components/Heading';
import { NextLink } from '../../common/components/NextLink';
import { LandingArrowButton } from '../components/LandingArrowLinkButton';
import { LandingFooter } from '../components/LandingFooter';
import {
  LandingIconBuildTogether,
  LandingIconClubAlumni,
  LandingIconCommunity,
  LandingIconCreators,
  LandingIconLaunch,
  LandingIconOnboarding,
  LandingIconPaywall,
  LandingIconPersonal,
  LandingIconThreads,
  LandingIconWork,
} from '../components/LandingIcons';
import { LandingNav } from '../components/LandingNav';
import { LandingPageExtraFeatures } from '../components/LandingPageExtraFeatures';
import {
  LandingHeroButton,
  LandingHeroHeading,
  LandingHeroSubHeading,
  LandingSectionDemo,
  LandingSectionFeature,
  LandingSectionFeatureHeading,
  LandingSectionFeatureImage,
  LandingSectionFeatureLarge,
  LandingSectionFeatureMedium,
  LandingSectionFeatureSmall,
  LandingSectionFeatureSmallHeading,
  LandingSectionFeatureText,
  LandingSectionHeading,
  LandingSectionHeadingSmall,
} from '../components/LandingSections';
import { marketingTheme } from '../theme/marketingTheme';

('use client');

export type LandingPageVersion = {
  heroHeading: [string, string, string];
  heroSubHeading: string;
};

export const LandingPage: React.FC<{
  user?: ViewerQuery['viewer'];
  version: LandingPageVersion;
}> = ({ user, version }) => {
  const [signInModalIsOpen, setSignInModalIsOpen] = useState(false);
  const title = 'Frond';
  const description = `Building new spaces for communities that can't share a place.`;

  return (
    <SignInRedirectGuard shouldRedirect={IS_DEMO}>
      <ThemeProvider theme={marketingTheme}>
        <NextSeo
          title={title}
          description={description}
          canonical={BASE_URL}
          openGraph={{
            images: [
              {
                url: DEFAULT_OG_IMAGE_URL,
                alt: title,
              },
            ],
            url: BASE_URL,
            type: 'website',
            title,
            description,
          }}
        />

        <x.div
          container={{ lg: true }}
          px={{ _: 0, lg: 7.5 }}
          mx="auto"
          boxSizing="border-box"
          position="relative"
        >
          {/* Navigation */}
          <x.div
            position={{ _: 'static', lg: 'sticky' }}
            top={0}
            h={0}
            px={{ _: 8, lg: 0 }}
            zIndex={10}
          >
            <LandingNav user={user} noLogoOnMobile />
          </x.div>
          <x.div
            display="flex"
            alignItems="center"
            h={{ _: 'calc(100vh - 110px)', lg: 'calc(100vh - 120px)' }}
            minH={{ _: '568px', md: '720px' }}
            w="100%"
            pt={{ _: 10, lg: 0 }}
            boxSizing="border-box"
          >
            <x.div
              display="flex"
              flexDirection="column"
              alignItems="center"
              w="100%"
              pb={12}
            >
              <x.div display={{ _: 'block', lg: 'none' }} mb={6} w={129}>
                <FrondLogo horizontal />
              </x.div>
              <LandingHeroHeading>
                {version.heroHeading[0]}
                <br />
                {version.heroHeading[1]}{' '}
                <x.span color="brand.300">{version.heroHeading[2]}</x.span>
              </LandingHeroHeading>
              <LandingHeroSubHeading>
                {version.heroSubHeading}
              </LandingHeroSubHeading>
              <x.div
                display="flex"
                spaceX={{ _: 0, lg: 3 }}
                spaceY={{ _: 3, lg: 0 }}
                flexDirection={{ _: 'column', lg: 'row' }}
              >
                <LandingHeroButton
                  variant="primary"
                  label="Give Frond a try"
                  onClick={() => setSignInModalIsOpen(true)}
                />
                <LandingHeroButton
                  variant="secondary"
                  label="Schedule a call with us"
                  // Can't use href because schedule is an internal route to a redirect
                  // and next/link doesn't recognize that
                  onClick={() => {
                    window.location.href = routes.marketing.schedule();
                  }}
                />
              </x.div>
            </x.div>
          </x.div>

          {/* App Demo */}
          <LandingSectionDemo display={{ _: 'none', lg: 'flex' }} mb={25} />
          {/* Setup + Onboarding */}
          <LandingSectionHeading display={{ _: 'none', lg: 'block' }}>
            Effortless from the start
          </LandingSectionHeading>
          <x.div
            display="flex"
            spaceY={10}
            flexDirection="column"
            mb={{ _: 16, lg: 25 }}
          >
            <LandingSectionFeatureLarge
              flexDirection={{ _: 'column', lg: 'row' }}
              alignItems="center"
              justifyContent="space-between"
            >
              <x.div boxSizing="border-box" w={{ _: null, lg: 461 }}>
                <LandingIconCommunity />
                <LandingSectionFeatureHeading>
                  Setup in seconds
                </LandingSectionFeatureHeading>
                <LandingSectionFeatureText>
                  Running a community is hard work, setting up should be the
                  easy part. Frond&apos;s simple settings and templates mean
                  that your community is only a few clicks away. And bringing
                  people in is as easy as sending a link.
                </LandingSectionFeatureText>
                <x.span onClick={() => setSignInModalIsOpen(true)}>
                  <LandingArrowButton mt={5} mb={8}>
                    Create your own community
                  </LandingArrowButton>
                </x.span>
              </x.div>
              <LandingSectionFeatureImage
                display={{ _: 'block', xl: 'none' }}
                src="/landing-assets/section-community-mobile_k0f6kh.png"
                w={1089 / 3}
                h={1314 / 3}
                alignSelf="center"
              />
              <LandingSectionFeatureImage
                display={{ _: 'none', xl: 'block' }}
                src="/landing-assets/section-community_vw3bfn.png"
                w={1569 / 3}
                h={1725 / 3}
              />
            </LandingSectionFeatureLarge>

            <LandingSectionFeatureLarge
              flexDirection={{ _: 'column', lg: 'row-reverse' }}
              alignItems="center"
              justifyContent="space-between"
            >
              <x.div boxSizing="border-box" w={{ _: null, xl: 461 }}>
                <LandingIconOnboarding />
                <LandingSectionFeatureHeading>
                  Onboarding is a breeze
                </LandingSectionFeatureHeading>
                <LandingSectionFeatureText>
                  Frond makes every new member feel at home. When someone joins
                  they get a personal greeting from you. And our member
                  introductions help spark connections in the community so you
                  don&apos;t have to do all of the social integration work
                  yourself.
                </LandingSectionFeatureText>
              </x.div>
              <LandingSectionFeatureImage
                display={{ _: 'none', xl: 'block' }}
                src="/landing-assets/section-onboarding_xrwgzn.png"
                w={1677 / 3}
                h={1638 / 3}
              />
            </LandingSectionFeatureLarge>
          </x.div>
          {/* Threads + Groups + Public communities */}
          <LandingSectionHeading display={{ _: 'none', lg: 'block' }}>
            Scale fun, not noise
          </LandingSectionHeading>
          <x.div
            display="flex"
            flexDirection="column"
            spaceY={10}
            mb={{ _: 16, lg: 25 }}
          >
            <x.div
              display="flex"
              spaceX={{ _: 0, lg: 10 }}
              spaceY={{ _: 10, lg: 0 }}
              flexDirection={{ _: 'column', lg: 'row' }}
            >
              <LandingSectionFeature>
                <LandingIconThreads />
                <LandingSectionFeatureHeading>
                  Threads are back (and better than ever)
                </LandingSectionFeatureHeading>
                <LandingSectionFeatureText mb={{ _: 7, lg: 16 }}>
                  Chats are fun until they aren&apos;t. Frond conversations are
                  threaded because they&apos;re easier to follow, spark richer
                  conversation, and scale endlessly. Each thread starts with
                  something to get the ball rolling: a photo, video, link, or
                  longer-form writing.
                </LandingSectionFeatureText>
                <LandingSectionFeatureImage
                  display={{ _: 'block', lg: 'none' }}
                  src="/landing-assets/section-post-mobile_rol0cc.png"
                  w={1078 / 3}
                  h={1529 / 3}
                  alignSelf="center"
                  pb={4}
                />
                <LandingSectionFeatureImage
                  display={{ _: 'none', lg: 'block' }}
                  src="/landing-assets/section-post_qxhyje.png"
                  w={1073 / 2}
                  h={1186 / 2}
                  alignSelf="center"
                />
              </LandingSectionFeature>

              <LandingSectionFeature overflow="hidden">
                <LandingIconPaywall />
                <LandingSectionFeatureHeading>
                  Charge for access
                </LandingSectionFeatureHeading>
                <LandingSectionFeatureText mb={{ _: 7, lg: 16 }}>
                  Paywalls allow you to charge your members for access to
                  certain groups within a community. Create groups with course
                  content, exclusive meetups, or priority support for your
                  business. Revenue is paid out monthly with transaction fees as
                  low as 1%.
                </LandingSectionFeatureText>
                <LandingSectionFeatureImage
                  src="/landing-assets/section-paywall_vfhazr.png"
                  w={1074 / 2}
                  h={1210 / 2}
                  alignSelf="center"
                />
              </LandingSectionFeature>
            </x.div>
            <LandingSectionFeature
              flexDirection={{ _: 'column', lg: 'row' }}
              alignItems="center"
              justifyContent="space-between"
            >
              <x.div boxSizing="border-box" w={{ _: null, lg: 410 }}>
                <LandingSectionFeatureHeading>
                  And many more features...
                </LandingSectionFeatureHeading>
                <LandingSectionFeatureText>
                  With it’s simple design, curated features and built-in
                  payments, Frond sets a new standard for community building.
                </LandingSectionFeatureText>
                <NextLink href={routes.marketing.features()} styleInheritColor>
                  <LandingArrowButton mt={5}>
                    Explore Frond’s features
                  </LandingArrowButton>
                </NextLink>
              </x.div>
              <LandingPageExtraFeatures />
            </LandingSectionFeature>
          </x.div>
          {/* Use cases */}
          <x.div display={{ _: 'none', lg: 'block' }}>
            <LandingSectionHeading>
              Built for communities of all kinds
            </LandingSectionHeading>
            <x.div display="flex" flexDirection="column" spaceY={10} mb={25}>
              <x.div display="flex" spaceX={10}>
                <LandingSectionFeatureSmall bg="blue.75">
                  <LandingIconCreators />
                  <LandingSectionFeatureSmallHeading>
                    Content creators
                  </LandingSectionFeatureSmallHeading>
                  <LandingSectionFeatureText>
                    Transform your content into a thriving community. Share
                    exclusive content and courses behind a paywall, and give
                    your audience space to connect.
                  </LandingSectionFeatureText>
                </LandingSectionFeatureSmall>
                <LandingSectionFeatureSmall bg="yellow.75">
                  <LandingIconBuildTogether />
                  <LandingSectionFeatureSmallHeading>
                    Building with customers
                  </LandingSectionFeatureSmallHeading>
                  <LandingSectionFeatureText>
                    Create a community for your most engaged and loyal
                    customers. Share progress, collect ideas and bug reports,
                    and create exclusive groups for your paid customers.
                  </LandingSectionFeatureText>
                </LandingSectionFeatureSmall>
              </x.div>
              <x.div display="flex" spaceX={10}>
                <LandingSectionFeatureSmall bg="red.75">
                  <LandingIconWork />
                  <LandingSectionFeatureSmallHeading>
                    Work
                  </LandingSectionFeatureSmallHeading>
                  <LandingSectionFeatureText>
                    Work better, by creating a private space for your team to
                    collaborate and have a little fun together.
                  </LandingSectionFeatureText>
                </LandingSectionFeatureSmall>
                <LandingSectionFeatureSmall>
                  <LandingIconPersonal />
                  <LandingSectionFeatureSmallHeading>
                    Personal
                  </LandingSectionFeatureSmallHeading>
                  <LandingSectionFeatureText>
                    Create a private space to share updates, photos and videos
                    with your friends and family.
                  </LandingSectionFeatureText>
                </LandingSectionFeatureSmall>
                <LandingSectionFeatureSmall bg="purple.75">
                  <LandingIconClubAlumni />
                  <LandingSectionFeatureSmallHeading>
                    Clubs and alumni groups
                  </LandingSectionFeatureSmallHeading>
                  <LandingSectionFeatureText>
                    Announce events, talk strategy and help your group to stay
                    in touch or get know each other.
                  </LandingSectionFeatureText>
                </LandingSectionFeatureSmall>
              </x.div>
            </x.div>
          </x.div>
          {/* Mobile */}
          <LandingSectionHeading display={{ _: 'none', lg: 'block' }}>
            Never more than a tap away
          </LandingSectionHeading>
          <x.div
            display="flex"
            spaceY={10}
            flexDirection="column"
            mb={{ _: 16, lg: 25 }}
          >
            <LandingSectionFeatureLarge
              flexDirection={{ _: 'column', lg: 'row-reverse' }}
              alignItems="center"
              justifyContent="space-between"
              py={0}
              pt={{ _: 7, lg: 88 }}
              pb={{ _: 7, lg: '32px' }}
            >
              <x.div boxSizing="border-box" w={{ _: null, xl: 461 }} pl={8}>
                <LandingIconOnboarding />
                <LandingSectionFeatureHeading>
                  Frond mobile app
                </LandingSectionFeatureHeading>
                <LandingSectionFeatureText>
                  Our mobile app brings the simple magic of Frond to your
                  fingertips. With threaded conversations and instant push
                  notifications, your members will stay up-to-date on the go.
                  Meet your members where they are and grow your community
                  faster.
                </LandingSectionFeatureText>
                <x.a
                  href="https://apps.apple.com/us/app/frond-start-your-community/id6504026358"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LandingArrowButton mt={5} mb={8}>
                    Get the app
                  </LandingArrowButton>
                </x.a>
              </x.div>
              <LandingSectionFeatureImage
                display={{ _: 'none', xl: 'block' }}
                src="/landing-assets/section-mobile.png"
                w={1210 / 2}
                h={1184 / 2}
              />
            </LandingSectionFeatureLarge>
          </x.div>
          {/* Free + Open source */}
          <LandingSectionHeading display={{ _: 'none', lg: 'block' }}>
            Free to start, open for all
          </LandingSectionHeading>
          <x.div
            display="flex"
            flexDirection={{ _: 'column', lg: 'row' }}
            spaceX={{ _: 0, lg: 10 }}
            spaceY={{ _: 10, lg: 0 }}
          >
            <LandingSectionFeatureMedium bg="green.75">
              <x.div display="flex" flexDirection="column" alignItems="center">
                <LandingIconLaunch />
                <LandingSectionHeadingSmall>
                  Free to start
                </LandingSectionHeadingSmall>
                <LandingSectionFeatureText textAlign="center">
                  Frond is the only community tool that&apos;s actually free to
                  start. No credit card required. No two week trial period. We
                  want you to see for yourself if Frond works for your
                  community. If it does, our Plus and Pro plans will give you
                  the tools you need to continue to flourish.
                </LandingSectionFeatureText>
                <Button
                  variant="green"
                  size="md"
                  label="View our plans"
                  href="/pricing"
                  mt={8}
                />
              </x.div>
            </LandingSectionFeatureMedium>
            <LandingSectionFeatureMedium>
              <x.div display="flex" flexDirection="column" alignItems="center">
                <LandingIconLaunch />
                <LandingSectionHeadingSmall>
                  Open source
                </LandingSectionHeadingSmall>
                <LandingSectionFeatureText textAlign="center">
                  We firmly believe in empowering you to control your own data.
                  Open sourcing Frond means that we are providing the tools to
                  put you in the driver&apos;s seat of your community&apos;s
                  future. It also reinforces our commitment to transparency and
                  collaborative growth, values that are at the core of what we
                  do.
                </LandingSectionFeatureText>
                <Button
                  variant="secondary"
                  size="md"
                  label="Read more"
                  href="/blog/frond-is-going-open-source"
                  mt={8}
                />
              </x.div>
            </LandingSectionFeatureMedium>
          </x.div>
          {/* Outro - desktop */}
          <x.div display={{ _: 'none', lg: 'block' }}>
            <x.div
              fontSize={76}
              fontWeight="bold"
              lineHeight="shorter"
              w={800}
              color="purple.300"
              mt={40}
            >
              Give your community space to grow
            </x.div>
            <Button
              variant="primary"
              label="Give Frond a try"
              onClick={() => setSignInModalIsOpen(true)}
              size="xl"
              mt={10}
              mb={40}
            />
          </x.div>
          {/* Outro - mobile */}
          <x.div
            display={{ _: 'flex', lg: 'none ' }}
            flexDirection="column"
            alignItems="center"
            mt={16}
          >
            <Heading
              variant="2xl"
              color="purple.300"
              textAlign="center"
              w={300}
              mt={0}
            >
              Give your community space to grow
            </Heading>
            <Button
              variant="primary"
              label="Give Frond a try"
              href={routes.signin()}
              size="md"
              mt={10}
              mb={16}
            />
          </x.div>
          <LandingFooter />
        </x.div>

        <SignInModal
          isOpen={signInModalIsOpen}
          onDismiss={() => setSignInModalIsOpen(false)}
        />
      </ThemeProvider>
    </SignInRedirectGuard>
  );
};
