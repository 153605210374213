import { SystemProps, x } from '@xstyled/styled-components';
import { motion } from 'framer-motion';
import { PropsWithChildren, useState } from 'react';

import { DEMO_URL } from '../../../config/constants';
import { Button, ButtonProps } from '../../common/components/Button';
import { Heading } from '../../common/components/Heading';
import { Image } from '../../common/components/Image';
import { Link } from '../../common/components/Link';
import { Text } from '../../common/components/Text';
import { LandingArrowButton } from './LandingArrowLinkButton';

export const LandingHeroHeading: React.FC<PropsWithChildren<SystemProps>> = ({
  children,
  ...props
}) => {
  return (
    <Heading
      textAlign="center"
      fontSize={{ _: '2xl', lg: '4.75rem' }}
      lineHeight={{ _: '125%', lg: '110%' }}
      mx="auto"
      maxW={900}
      mb={{ _: 6, lg: 10 }}
      {...props}
    >
      {children}
    </Heading>
  );
};

export const LandingHeroSubHeading: React.FC<
  PropsWithChildren<SystemProps>
> = ({ children, ...props }) => {
  return (
    <x.p
      fontSize={{ _: 18, lg: 22 }}
      lineHeight="base"
      textAlign="center"
      maxW={923}
      mb={10}
      px={4}
      {...props}
    >
      {children}
    </x.p>
  );
};

export const LandingHeroButton: React.FC<ButtonProps> = (props) => {
  return (
    <>
      <Button display={{ _: 'none', lg: 'block' }} size="xl" {...props} />
      <Button display={{ _: 'block', lg: 'none' }} {...props} />
    </>
  );
};

export const LandingSectionDemo: React.FC<SystemProps> = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <x.div
      bg="yellow.100"
      w="full"
      pt={{ _: 0, xl: 12 }}
      borderRadius="lg-xxl lg-xxl 0 0"
      display="flex"
      alignItems="flex-end"
      justifyContent="center"
      {...props}
    >
      <Link
        href={DEMO_URL}
        target="_blank"
        rel="noopener noreferrer"
        styleInheritColor
      >
        <x.div
          display="flex"
          flexDirection="column"
          alignItems="center"
          // justifyContent="center"
          position="relative"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <x.div>
            <motion.div animate={{ scale: isHovered ? 1.2 : 1 }}>
              <LandingArrowButton variant="dark" boxShadow="default" mb={3}>
                Try the live demo
              </LandingArrowButton>
            </motion.div>
          </x.div>
          <Image
            width={1080}
            height={594}
            src="/landing-assets/section-app_lni2zv.png"
          />
        </x.div>
      </Link>
    </x.div>
  );
};

export const LandingSectionHeading: React.FC<
  PropsWithChildren<SystemProps>
> = ({ children, ...props }) => {
  return (
    <Heading
      textAlign="center"
      variant="4xl"
      mx="auto"
      w={600}
      mb={16}
      {...props}
    >
      {children}
    </Heading>
  );
};

export const LandingSectionHeadingSmall: React.FC<
  PropsWithChildren<SystemProps>
> = ({ children, ...props }) => {
  return (
    <Heading
      textAlign="center"
      variant={{ _: '2xl', lg: '3xl' }}
      mx="auto"
      mb={6}
      {...props}
    >
      {children}
    </Heading>
  );
};

export const LandingSectionFeature: React.FC<
  PropsWithChildren<SystemProps>
> = ({ children, ...props }) => {
  return (
    <x.div
      display="flex"
      flexDirection="column"
      bg="gray.50"
      borderRadius={{ _: 0, lg: 'lg-xxl' }}
      px={{ _: 6, lg: 16 }}
      py={{ _: 7, lg: 16 }}
      {...props}
    >
      {children}
    </x.div>
  );
};

export const LandingSectionFeatureMedium: React.FC<
  PropsWithChildren<SystemProps>
> = ({ children, ...props }) => {
  return (
    <LandingSectionFeature
      px={{ _: 6, lg: 12, xl: 88 }}
      py={{ _: 7, lg: 8, xl: 16 }}
      {...props}
    >
      {children}
    </LandingSectionFeature>
  );
};

export const LandingSectionFeatureLarge: React.FC<
  PropsWithChildren<SystemProps>
> = ({ children, ...props }) => {
  return (
    <LandingSectionFeature
      px={{ _: 6, lg: 88 }}
      py={{ _: 7, lg: 88 }}
      {...props}
    >
      {children}
    </LandingSectionFeature>
  );
};

export const LandingSectionFeatureHeading: React.FC<
  PropsWithChildren<SystemProps>
> = ({ children, ...props }) => {
  return (
    <x.h3
      fontSize={{ _: 'md', lg: 22 }}
      fontWeight={{ _: 'semibold', lg: 'bold' }}
      lineHeight="tall"
      mb={3}
      {...props}
    >
      {children}
    </x.h3>
  );
};

export const LandingSectionFeatureText: React.FC<
  PropsWithChildren<SystemProps>
> = ({ children, ...props }) => {
  return (
    <Text variant={{ _: 'md', lg: 'lg' }} {...props}>
      {children}
    </Text>
  );
};

export const LandingSectionFeatureImage: React.FC<
  { src: string } & SystemProps
> = ({ w, h, src, ...props }) => {
  return (
    // Negative margins account for drop shadow built into images
    <x.div mt={-7} mb={-8} mx={-7} {...props}>
      <Image width={w as number} height={h as number} src={src} />
    </x.div>
  );
};

export const LandingSectionFeatureLittle: React.FC<{
  name: string;
  description: string;
}> = ({ name, description, ...props }) => {
  return (
    <Text variant={{ _: 'md', lg: 'lg' }} {...props}>
      <x.span fontWeight="semibold">{name}.</x.span> {description}
    </Text>
  );
};

export const LandingSectionFeatureSmall: React.FC<
  PropsWithChildren<SystemProps>
> = ({ children, ...props }) => {
  return (
    <LandingSectionFeature
      flexGrow={1}
      flexBasis={0}
      px={{ _: 6, lg: 8 }}
      py={{ _: 7, lg: 10 }}
      {...props}
    >
      {children}
    </LandingSectionFeature>
  );
};

export const LandingSectionFeatureSmallHeading: React.FC<
  PropsWithChildren<SystemProps>
> = ({ children, ...props }) => {
  return (
    <x.h4 fontSize={18} fontWeight="bold" lineHeight="tall" pb={4} {...props}>
      {children}
    </x.h4>
  );
};
