import { x } from '@xstyled/styled-components';

import { CUSTOM_DOMAINS_LAUNCHED } from '../../../config/constants';
import { Text } from '../../common/components/Text';

const FEATURES = [
  { emoji: '🔰', label: 'Member roles' },
  { emoji: '🎛️', label: 'Insights' },
  { emoji: '💬', label: 'Messages' },
  { emoji: '🎓', label: 'Courses' },
  { emoji: '📅', label: 'Events' },
  { emoji: '😃', label: 'Member profiles' },
  { emoji: '️️⚗️️', label: 'Widget' },
  { emoji: '📊', label: 'Polls' },
  { emoji: '👋', label: 'Member onboarding' },
  { emoji: '🏞', label: 'Rich posts' },
  { emoji: '📢', label: 'Public communities' },
  { emoji: '🔐️', label: 'Private communities' },
];

if (CUSTOM_DOMAINS_LAUNCHED) {
  FEATURES.splice(6, 0, { emoji: '🌐', label: 'Custom domains' });
}

const ExtraFeature: React.FC<{ emoji: string; label: string }> = ({
  emoji,
  label,
}) => {
  return (
    <x.div
      display="flex"
      flexDirection="row"
      alignItems="center"
      bg="white"
      borderRadius="md-lg"
      boxShadow="glossyModal"
      py={{ _: 2, xl: 3 }}
      pr={{ _: 4, xl: 5 }}
      pl={{ _: 3.5, xl: 4.5 }}
      mb={{ _: 4, xl: 6 }}
      mr={{ _: 4, xl: 6 }}
    >
      <Text variant={{ _: 'xs', xl: 'sm' }} mr={2}>
        {emoji}
      </Text>
      <Text variant={{ _: 'sm-semibold', xl: 'md-s-semibold' }}>{label}</Text>
    </x.div>
  );
};

export const LandingPageExtraFeatures: React.FC = () => {
  return (
    <x.div
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="center"
      maxW={{ _: undefined, lg: 516 }}
      // mx={{ _ : 2, lg: undefined }}
      mt={{ _: 8, lg: undefined }}
      mb={{ _: undefined, lg: -6 }}
    >
      {FEATURES.map((feature) => (
        <ExtraFeature key={feature.label} {...feature} />
      ))}
    </x.div>
  );
};
